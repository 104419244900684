
document.addEventListener("DOMContentLoaded", function() {

    // action du bouton des cookies
    document.querySelector("#cookies_close button").addEventListener("click", () => {
        document.getElementById("alert-cookies").remove();
        localStorage.setItem("cookies-accepted", true);
    });

    // afficher le bandeau cookies en fonction du localstorage
    if (localStorage.getItem("cookies-accepted") != null) {
        document.getElementById("alert-cookies").remove();
    } else {
        document.getElementById("alert-cookies").style.display = "flex";
    }
});